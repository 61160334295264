import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Box, Grid, Paper } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

// Lazy load the premium upgrade button component
const HandleUpgradeToPremiumButton = lazy(() => import('../components/HandleUpgradeToPremiumButton'));

// Hero section styles with a modern gradient background
const heroBoxStyles = {
  minHeight: '100vh',
  background: 'linear-gradient(135deg, #1e3c72, #2a5298)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
};

// Container for the content with a light, professional look
const contentPaperStyles = {
  padding: '2rem',
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  maxWidth: 800,
  width: '100%',
};

// Updated typography for a bolder, clearer message
const headlineStyles = {
  fontSize: { xs: '2rem', md: '3rem' },
  fontWeight: 700,
  color: '#1e3c72',
  textAlign: 'center',
  marginBottom: '0.5rem',
};

const subHeadlineStyles = {
  fontSize: { xs: '1.25rem', md: '1.5rem' },
  textAlign: 'center',
  color: '#333',
  marginBottom: '0.5rem',
};

const italicTextStyles = {
  fontStyle: 'italic',
  textAlign: 'center',
  color: '#555',
  marginBottom: '2rem',
};

const buttonStyles = {
  mt: 2,
  px: 4,
  py: 1,
};

const HomePage = ({ user }) => {
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    if (!user) {
      setIsPremium(false);
      return;
    }

    const subscriptionsRef = collection(firestore, 'users', user.uid, 'subscriptions');
    const qSubscriptions = query(subscriptionsRef, where('status', '==', 'active'));

    const unsubscribe = onSnapshot(
      qSubscriptions,
      (querySnapshot) => {
        setIsPremium(!querySnapshot.empty);
      },
      (error) => {
        console.error('Error fetching subscription data:', error);
        setIsPremium(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Sign-in error:', error);
      alert('Sign-in failed. Please try again.');
    }
  };

  return (
    <Box sx={heroBoxStyles}>
      <Paper sx={contentPaperStyles}>
        <Grid container spacing={3}>
          {/* Headline & Subheadline */}
          <Grid item xs={12}>
            <Typography variant="h2" sx={headlineStyles}>
              Collect Verified Crypto Wallets
            </Typography>
            <Typography variant="h6" sx={subHeadlineStyles}>
              Simplify giveaways, whitelists, and ICOs by gathering your followers’ crypto addresses in one place.
            </Typography>
            <Typography variant="body2" sx={italicTextStyles}>
              Supports Bitcoin, Ethereum, and Solana.
            </Typography>
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            {user ? (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/create"
                  size="large"
                  sx={buttonStyles}
                >
                  Create Entry Form
                </Button>
                {!isPremium && (
                  <Suspense fallback={<div>Loading upgrade options...</div>}>
                    <Box sx={{ mt: 2 }}>
                      <HandleUpgradeToPremiumButton user={user} />
                    </Box>
                  </Suspense>
                )}
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignIn}
                  size="large"
                  sx={buttonStyles}
                >
                  Sign in for Instant Access
                </Button>
                <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
                  By signing in, you agree to our{' '}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link to="/policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </Link>.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default HomePage;
