/**
 * @file        /src/components/SharedRaffles.js
 * @version     2.1.1
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-25
 * 
 * @summary
 * Displays a list of Lists shared with the authenticated user within the WalletCollector application.
 * Handles data fetching from Firestore, manages loading and error states, and provides navigation to List details.
 * 
 * @description
 * The `SharedRaffles` component allows users to view Lists that have been shared with them by other List creators.
 * It fetches shared List permissions from Firestore and retrieves the corresponding List details. The component handles
 * Firestore's query limitations by batching requests when there are more than ten shared Lists. It also filters out any
 * raffles that have been marked as deleted. The user interface is built using Material-UI components to ensure responsiveness
 * and a consistent look and feel across different devices.
 * 
 * Key Features:
 * - **Data Fetching:** Retrieves shared List permissions and corresponding List details from Firestore.
 * - **Batch Processing:** Handles Firestore's 'in' query limitations by batching List ID requests when necessary.
 * - **Entrant Counts:** Fetches and displays the number of entrants for each shared raffle.
 * - **Error Handling:** Manages and displays errors that occur during data fetching.
 * - **Responsive UI:** Utilizes Material-UI components to provide a user-friendly and responsive interface.
 * - **Navigation:** Enables users to navigate to detailed views of each shared List.
 * - **Scalability:** Designed to efficiently handle a large number of shared Lists without performance degradation.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.1**: Corrected brand fetching logic to accurately retrieve and display brand names based on `brandOwnerUid` and `brandId`. Added entrant count fetching and display.
 * - **2.1.0**: Added inline documentation for improved readability and maintainability. Enhanced batch processing for Firestore queries to support more shared raffles.
 * - **2.0.0**: Refactored component for better performance and scalability. Improved error handling and user feedback mechanisms.
 * - **1.0.0**: Initial creation of the SharedRaffles component with basic data fetching and display functionality.
 */

import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
} from 'firebase/firestore';

/**
 * SharedRaffles Component
 * 
 * @param {object} user - The authenticated user object.
 */
const SharedRaffles = ({ user }) => {
  // State to hold the list of shared raffles
  const [sharedRaffles, setSharedRaffles] = useState([]);

  // State to manage loading status
  const [loading, setLoading] = useState(true);

  // State to capture any errors during data fetching
  const [error, setError] = useState('');

  // ====== Deletion Confirmation Dialog ======
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [raffleToDelete, setRaffleToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ====== Snackbar ======
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // ====== Brands State ======
  const [brandsMap, setBrandsMap] = useState({});
  const [brandsLoading, setBrandsLoading] = useState(true);

  // ====== Entrant Counts ======
  // To store entrant counts keyed by raffle ID
  const [entrantCounts, setEntrantCounts] = useState({});

  // ----------------------------------
  // 1) Fetch Shared Raffles and Entrant Counts
  // ----------------------------------
  useEffect(() => {
    if (!user) return;

    const fetchSharedRaffles = async () => {
      setLoading(true);
      setError('');
      try {
        // 1. Query sharedPermissions where sharedWithUid equals the current user's UID
        const sharedPermissionsRef = collection(firestore, 'sharedPermissions');
        const qShared = query(
          sharedPermissionsRef,
          where('sharedWithUid', '==', user.uid)
        );
        const sharedSnap = await getDocs(qShared);

        // Extract raffle IDs and permission document IDs from the sharedPermissions documents
        const raffleIds = sharedSnap.docs.map(docSnap => docSnap.data().raffleId);
        const permissionDocs = sharedSnap.docs; // Keep track of permission documents

        // If no raffles are shared, update the state accordingly
        if (raffleIds.length === 0) {
          setSharedRaffles([]);
          setLoading(false);
          setBrandsLoading(false);
          setEntrantCounts({});
          return;
        }

        // 2. Fetch raffles corresponding to the raffleIds in batches of 10
        const rafflesRef = collection(firestore, 'raffles');
        const fetchRafflesBatch = async (ids, permissionDocsBatch) => {
          const q = query(
            rafflesRef,
            where('__name__', 'in', ids)
          );
          const rafflesSnapshot = await getDocs(q);
          return rafflesSnapshot.docs.map(docSnap => {
            const raffle = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            // Find the corresponding sharedPermission document
            const permissionDoc = permissionDocsBatch.find(
              perm => perm.data().raffleId === docSnap.id
            );
            return {
              ...raffle,
              sharedPermissionId: permissionDoc ? permissionDoc.id : null, // Add permission document ID
            };
          });
        };

        let fetchedRaffles = [];

        // Process raffleIds in batches of 10 to comply with Firestore's 'in' query limit
        for (let i = 0; i < raffleIds.length; i += 10) {
          const batchIds = raffleIds.slice(i, i + 10);
          const batchPermissions = permissionDocs.filter(doc => batchIds.includes(doc.data().raffleId));
          const batchRaffles = await fetchRafflesBatch(batchIds, batchPermissions);
          fetchedRaffles = [...fetchedRaffles, ...batchRaffles];
        }

        // 3. Filter out raffles that have been marked as deleted
        fetchedRaffles = fetchedRaffles.filter(raffle => !raffle.deleted);

        // 4. Fetch brand information if the raffle has a brand
        const brandInfoList = fetchedRaffles
          .map(r => r.selectedBrandInfo)
          .filter(info => info && info.brandOwnerUid && info.brandId);

        // Create a unique list of brandOwnerUid and brandId pairs
        const uniqueBrandPairs = brandInfoList.reduce((acc, info) => {
          const key = `${info.brandOwnerUid}_${info.brandId}`;
          if (!acc.has(key)) {
            acc.set(key, { brandOwnerUid: info.brandOwnerUid, brandId: info.brandId });
          }
          return acc;
        }, new Map());

        const uniqueBrands = Array.from(uniqueBrandPairs.values());

        const fetchBrands = async () => {
          if (uniqueBrands.length === 0) {
            setBrandsMap({});
            setBrandsLoading(false);
            return;
          }

          const brandsMapTemp = {};

          await Promise.all(
            uniqueBrands.map(async ({ brandOwnerUid, brandId }) => {
              const brandDocRef = doc(firestore, 'users', brandOwnerUid, 'brands', brandId);
              const brandDocSnap = await getDoc(brandDocRef);
              if (brandDocSnap.exists()) {
                const brandData = brandDocSnap.data();
                if (!brandData.deleted) {
                  const key = `${brandOwnerUid}_${brandId}`;
                  brandsMapTemp[key] = {
                    name: brandData.name,
                    isShared: true, // Since these are shared raffles
                  };
                }
              }
            })
          );

          setBrandsMap(brandsMapTemp);
          setBrandsLoading(false);
        };

        await fetchBrands();

        // 5. Fetch entrant counts for each raffle
        const fetchEntrantCounts = async () => {
          const counts = {};

          await Promise.all(
            fetchedRaffles.map(async (raffle) => {
              const raffleEntriesRef = collection(firestore, 'raffleEntries');
              const qEntries = query(
                raffleEntriesRef,
                where('raffleId', '==', raffle.id)
              );
              const entriesSnapshot = await getDocs(qEntries);
              counts[raffle.id] = entriesSnapshot.size || 0;
            })
          );

          setEntrantCounts(counts);
        };

        await fetchEntrantCounts();

        // Update the state with the fetched raffles
        setSharedRaffles(fetchedRaffles);
      } catch (err) {
        console.error('Error fetching shared raffles:', err);
        setError('Failed to load shared raffles.');
        setBrandsLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchSharedRaffles();
  }, [user]);

  // ----------------------------------
  // 2) Handlers
  // ----------------------------------
  const handleOpenDeleteDialog = (raffle) => {
    setRaffleToDelete(raffle);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setRaffleToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteSharedRaffle = async () => {
    if (!raffleToDelete) return;

    setDeleteLoading(true);
    try {
      // Reference to the sharedPermission document
      const permissionDocRef = doc(
        firestore,
        'sharedPermissions',
        raffleToDelete.sharedPermissionId
      );

      // Delete the sharedPermission document
      await deleteDoc(permissionDocRef);

      // Update local state to remove the deleted raffle
      setSharedRaffles((prev) => prev.filter((r) => r.id !== raffleToDelete.id));

      // Remove the entrant count for the deleted raffle
      setEntrantCounts((prev) => {
        const updated = { ...prev };
        delete updated[raffleToDelete.id];
        return updated;
      });

      setSnackbar({
        open: true,
        message: 'Access to the List has been revoked successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting shared List:', error);
      setSnackbar({
        open: true,
        message: 'Failed to revoke access. Please try again.',
        severity: 'error',
      });
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteDialog();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // ----------------------------------
  // 3) Rendering
  // ----------------------------------
  if (!user) {
    return null; // Alternatively, display a message prompting the user to sign in
  }

  return (
  <Box sx={{ mt: 4 }}>
  <Typography variant="h5" gutterBottom>
    Lists Shared With Me
  </Typography>
  <Paper elevation={3} sx={{ p: 2 }}>
    {loading || brandsLoading ? (
      // Display a loading spinner while fetching data
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Box>
    ) : error ? (
      // Display an error message if data fetching fails
      <Typography color="error">{error}</Typography>
    ) : sharedRaffles.length === 0 ? (
      // Inform the user if no raffles have been shared
      <Typography variant="body2">
        No lists have been shared with you.
      </Typography>
    ) : (
      // Display the list of shared raffles
      sharedRaffles.map((raffle) => {
        const { id, name, raffleType, entryDeadline, selectedBrandInfo } = raffle;
        const isOpen = new Date(entryDeadline) > new Date();
        const brandId = selectedBrandInfo?.brandId || null;
        const brandOwnerUid = selectedBrandInfo?.brandOwnerUid || null;
        const brandKey = brandOwnerUid && brandId ? `${brandOwnerUid}_${brandId}` : null;
        const brandInfo = brandKey ? brandsMap[brandKey] : null;

        // Determine the entrant count and maximum entrants for the horizontal bar.
        // (Ensure your entrantCounts object is structured as expected.)
        const EntrantCount = entrantCounts[id] || 0;
        const maxEntrants = Math.max(...Object.values(EntrantCount), 100);

        return (
          <Paper key={id} elevation={3} sx={{ p: 2, mb: 2 }}>
            {/* Mobile Layout: xs only */}
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {/* Raffle Details (stacked vertically) */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">
                  {name || 'Untitled'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {raffleType || 'N/A'}
                </Typography>
                {brandInfo && (
                  <Typography variant="caption" color="primary" sx={{ mt: 0.5 }}>
                    Brand: {brandInfo.name}
                    {brandInfo.isShared && ' (Shared With Me)'}
                  </Typography>
                )}
              </Box>

              {/* Open/Closed Label centered */}
              <Box
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: isOpen ? 'green' : 'red',
                }}
              >
                {isOpen ? 'Open' : 'Closed'}
              </Box>

              {/* Full-width Progress Bar */}
              <Box>
                <Tooltip title={`Entrants: ${EntrantCount}`} arrow>
                  <Box
                    sx={{
                      position: 'relative',
                      height: 24,
                      width: '100%',
                      backgroundColor: '#e0e0e0',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${(EntrantCount / maxEntrants) * 100}%`,
                        background: 'linear-gradient(90deg, #2196f3 0%, #21cbf3 100%)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        transition: 'width 0.4s ease',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>

              {/* Action Icon Buttons centered */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 1,
                  flexWrap: 'wrap',
                }}
              >
                <Tooltip title="View List" arrow>
                  <IconButton
                    component={Link}
                    to={`/raffle/${id}`}
                    aria-label="View"
                    color="secondary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete List" arrow>
                  <IconButton
                    color="error"
                    onClick={() => handleOpenDeleteDialog(raffle)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {/* Desktop/Laptop Layout: sm and up */}
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap',
              }}
            >
              {/* Raffle Details */}
              <Box sx={{ flex: 1, minWidth: '130px' }}>
                <Typography variant="subtitle1" noWrap>
                  {name || 'Untitled'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {raffleType || 'N/A'}
                </Typography>
                {brandInfo && (
                  <Typography
                    variant="caption"
                    color="primary"
                    sx={{ mt: 0.5, display: 'block' }}
                  >
                    Brand: {brandInfo.name}
                    {brandInfo.isShared && ' (Shared With Me)'}
                  </Typography>
                )}
              </Box>

              {/* Open/Closed Label and Fixed-Width Progress Bar */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  minWidth: { sm: '220px' },
                }}
              >
                <Box
                  sx={{
                    minWidth: '60px',
                    textAlign: 'center',
                    color: isOpen ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {isOpen ? 'Open' : 'Closed'}
                </Box>
                <Box sx={{ width: '975px' }}>
                  <Tooltip title={`Entrants: ${EntrantCount}`} arrow>
                    <Box
                      sx={{
                        position: 'relative',
                        height: 24,
                        backgroundColor: '#e0e0e0',
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.2)',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          bottom: 0,
                          width: `${(EntrantCount / maxEntrants) * 100}%`,
                          background: 'linear-gradient(90deg, #2196f3 0%, #21cbf3 100%)',
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                          transition: 'width 0.4s ease',
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>

              {/* Action Icon Buttons */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'wrap',
                }}
              >
                <Tooltip title="View List" arrow>
                  <IconButton
                    component={Link}
                    to={`/raffle/${id}`}
                    aria-label="View"
                    color="secondary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete List" arrow>
                  <IconButton
                    color="error"
                    onClick={() => handleOpenDeleteDialog(raffle)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        );
      })
    )}
  </Paper>
    {/* Deletion Confirmation Dialog */}
<Dialog
  open={deleteDialogOpen}
  onClose={handleCloseDeleteDialog}
  aria-labelledby="delete-dialog-title"
  aria-describedby="delete-dialog-description"
>
  <DialogTitle id="delete-dialog-title">Revoke Access</DialogTitle>
  <DialogContent>
    <Typography>
      Are you sure you want to revoke your access to <strong>{raffleToDelete?.name}</strong>?
      This action will remove the list from your shared lists.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDeleteDialog} disabled={deleteLoading}>
      Cancel
    </Button>
    <Button
      onClick={handleDeleteSharedRaffle}
      color="error"
      variant="contained"
      disabled={deleteLoading}
    >
      {deleteLoading ? <CircularProgress size={24} /> : 'Delete List'}
    </Button>
  </DialogActions>
</Dialog>


      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SharedRaffles;
