/**
 * @file        RafflePage.js
 * @version     2.0.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Displays detailed information about a specific List within the WalletCollector app. This page allows users
 * to submit their wallet addresses to enter the raffle and provides List owners with the ability to view
 * all existing entries. Key features include address validation, conditional rendering based on user roles,
 * and integration with Firestore for data management.
 * 
 * @description
 * The `ListPage` component is a React functional component that presents comprehensive details about a specific
 * raffle. It enables participants to enter the List by submitting their Bitcoin Ethereum or Solana addresses and X
 * handles. For List owners, the component offers a view of all current entries, facilitating easy management and
 * monitoring of participant engagement. The component leverages Firebase Firestore for data retrieval and storage,
 * React Router for dynamic routing based on List IDs, and Material-UI (MUI) for consistent and responsive
 * UI design.
 * 
 * Key Features:
 * - **List Details Display:** Shows the List's name, description, type, entry deadline, maximum entries per address,
 *   and total number of entrants.
 * - **Entry Submission:** Allows users to submit their wallet addresses and X handles to enter the List, with
 *   validation to ensure data integrity.
 * - **Conditional Rendering:** Displays different UI elements based on whether the user is the List owner or a participant.
 * - **Verified Icon and Dialog:** Indicates if the List owner is premium (verified) and provides additional information
 *   through a dialog upon interaction.
 * - **Copyable Shareable Link:** Enables List owners to share a direct link to the List page, with a copy-to-clipboard
 *   feature for convenience.
 * - **Brand Integration:** If a brand is associated with the List, the component fetches and displays brand-specific data,
 *   enhancing the visual appeal and coherence of the List page.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.0.0**: Enhanced documentation and added comprehensive JSDoc annotations.
 * - **1.0.0**: Initial creation of the Raffle Page component.
 */

// src/pages/RafflePage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import RaffleEntryForm from '../components/RaffleEntryForm';
import RaffleEntriesList from '../components/RaffleEntriesList';
import { Helmet } from 'react-helmet';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getCountFromServer,
  getDocs,
} from 'firebase/firestore';
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Alert,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  Stack,
} from '@mui/material';

import verifiedIcon from '../assets/images/verifiedbutton.png';

const RafflePage = ({ user }) => {
  const { id } = useParams();
  const [raffle, setRaffle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalEntries, setTotalEntries] = useState(null);
  const [copied, setCopied] = useState(false);

  // State to track whether the raffle owner is premium (verified)
  const [isOwnerPremium, setIsOwnerPremium] = useState(false);

  // State to store the subscription "created" date
  const [subscriptionCreated, setSubscriptionCreated] = useState(null);

  // State to control open/close of the verified dialog
  const [openVerifiedDialog, setOpenVerifiedDialog] = useState(false);

  // States for Brand Data
  const [brand, setBrand] = useState(null);
  const [brandLoading, setBrandLoading] = useState(false);
  const [brandError, setBrandError] = useState(null);

  // New State: Track if the user has shared permissions
  const [hasSharedPermission, setHasSharedPermission] = useState(false);

  const formatSubDate = () => {
    if (!subscriptionCreated) return '';
    const dateObj = subscriptionCreated.toDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  const getRaffleStatus = () => {
    if (!raffle || !raffle.entryDeadline) return null;
    const now = new Date();
    const deadline = new Date(raffle.entryDeadline);
    return now < deadline ? 'open' : 'closed';
  };

  useEffect(() => {
    const fetchRaffleData = async () => {
      setLoading(true);
      setError(null);
      try {
        // 1) Fetch raffle details
        const raffleRef = doc(firestore, 'raffles', id);
        const raffleSnap = await getDoc(raffleRef);

        if (!raffleSnap.exists()) {
          setError('No such raffle found!');
          setLoading(false);
          return;
        }

        const raffleData = { id: raffleSnap.id, ...raffleSnap.data() };

        // 1.a) Fetch the raffle owner's username
        if (raffleData.ownerId) {
          try {
            const usernamesRef = collection(firestore, 'usernames');
            const qUsernames = query(
              usernamesRef,
              where('uid', '==', raffleData.ownerId)
            );
            const usernamesSnap = await getDocs(qUsernames);

            if (!usernamesSnap.empty) {
              const usernameData = usernamesSnap.docs[0].data();
              raffleData.ownerUsername = usernameData.username;
            } else {
              raffleData.ownerUsername = null;
            }
          } catch (usernameError) {
            console.error('Error fetching owner username:', usernameError);
            raffleData.ownerUsername = null;
          }
        } else {
          raffleData.ownerUsername = null;
        }

        setRaffle(raffleData);

        // 2) Fetch total number of entries
        const entriesRef = collection(firestore, 'raffleEntries');
        const qEntries = query(entriesRef, where('raffleId', '==', id));
        const countSnap = await getCountFromServer(qEntries);
        setTotalEntries(countSnap.data().count);

        // 3) Check subscription
        if (raffleData.ownerId) {
          try {
            const subscriptionsRef = collection(
              firestore,
              'users',
              raffleData.ownerId,
              'subscriptions'
            );
            const subsQuery = query(
              subscriptionsRef,
              where('status', '==', 'active')
            );
            const subsSnap = await getDocs(subsQuery);

            if (!subsSnap.empty) {
              setIsOwnerPremium(true);
              const firstSubDoc = subsSnap.docs[0];
              const subData = firstSubDoc.data();
              if (subData.created) {
                setSubscriptionCreated(subData.created);
              }
            }
          } catch (subError) {
            console.error('Error reading subscription status:', subError);
            setIsOwnerPremium(false);
          }
        }

        // 4) Fetch brand data (NEW LOGIC: handle either a direct .selectedBrand OR .selectedBrandInfo)
        if (
          raffleData?.selectedBrandInfo &&
          raffleData.selectedBrandInfo.brandOwnerUid &&
          raffleData.selectedBrandInfo.brandId
        ) {
          // This is a brand from either the owner or shared brand logic
          setBrandLoading(true);
          try {
            const brandRef = doc(
              firestore,
              'users',
              raffleData.selectedBrandInfo.brandOwnerUid,
              'brands',
              raffleData.selectedBrandInfo.brandId
            );
            const brandSnap = await getDoc(brandRef);
            if (brandSnap.exists()) {
              setBrand({ id: brandSnap.id, ...brandSnap.data() });
            } else {
              setBrandError('Selected brand does not exist (shared or owned).');
            }
          } catch (brandFetchError) {
            console.error('Error fetching brand data:', brandFetchError);
            setBrandError('Failed to fetch brand data (shared or owned).');
          } finally {
            setBrandLoading(false);
          }
        } else if (raffleData.selectedBrand && raffleData.ownerId) {
          // Old logic: direct brand ID from the list owner's own brand
          setBrandLoading(true);
          try {
            const brandRef = doc(
              firestore,
              'users',
              raffleData.ownerId,
              'brands',
              raffleData.selectedBrand
            );
            const brandSnap = await getDoc(brandRef);
            if (brandSnap.exists()) {
              setBrand({ id: brandSnap.id, ...brandSnap.data() });
            } else {
              setBrandError('Selected brand does not exist.');
            }
          } catch (brandFetchError) {
            console.error('Error fetching brand data:', brandFetchError);
            setBrandError('Failed to fetch brand data.');
          } finally {
            setBrandLoading(false);
          }
        }

        // 5) Check for shared permissions
        if (user && user.uid !== raffleData.ownerId) {
          try {
            const sharedPermissionsRef = collection(
              firestore,
              'sharedPermissions'
            );
            const sharedQuery = query(
              sharedPermissionsRef,
              where('raffleId', '==', id),
              where('sharedWithUid', '==', user.uid)
            );
            const sharedSnap = await getDocs(sharedQuery);
            if (!sharedSnap.empty) {
              setHasSharedPermission(true);
              console.log('User has shared permissions for this list.');
            }
          } catch (sharedError) {
            console.error('Error checking shared permissions:', sharedError);
            setHasSharedPermission(false);
          }
        }
      } catch (err) {
        console.error('Error fetching raffle:', err);
        setError('Failed to load raffle.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchRaffleData();
    }
  }, [id, user]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/List/${raffle.id}`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy link:', err);
    }
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading List...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const isOwner = user && user.uid === raffle.ownerId;
  const canViewEntries = isOwner || hasSharedPermission;

  // **Updated Conditional Checks**
  const pageBackgroundColor =
    !isOwner && !hasSharedPermission && brand?.backgroundColor
      ? brand.backgroundColor
      : '#ffffff';
  const pageTextColor =
    !isOwner && !hasSharedPermission && brand?.textColor
      ? brand.textColor
      : '#000000';

  const raffleStatus = getRaffleStatus();

  return (
    <Box
      sx={{
        padding: { xs: '1rem', md: '2rem' },
        minHeight: '100vh',
        backgroundColor: pageBackgroundColor, // Updated condition
        color: pageTextColor, // Updated condition
      }}
    >
      <Helmet>
        <title>{raffle.name} - Crypto Wallet Collector</title>
        <meta property="og:title" content={raffle.name} />
        <meta
          property="og:description"
          content={
            raffle.description || 'Join this exciting raffle and stand a chance to win!'
          }
        />
        <meta
          property="og:image"
          content={raffle.imageUrl || 'https://example.com/default-image.jpg'}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/List/${raffle.id}`}
        />
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              textAlign: { xs: 'center', md: 'left' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            {raffle.name}
            {isOwnerPremium && raffle.ownerUsername && (
              <Box
                component="img"
                src={verifiedIcon}
                alt="Verified"
                onClick={() => setOpenVerifiedDialog(true)}
                sx={{
                  display: 'inline-block',
                  height: '2em',
                  width: 'auto',
                  marginLeft: '0.5rem',
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }}
                role="button"
                aria-label={`View verification details for ${raffle.ownerUsername}`}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setOpenVerifiedDialog(true);
                  }
                }}
              />
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {raffle.description}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant="body2">
              <strong>Type:</strong> {raffle.raffleType}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              <Typography variant="body2">
                <strong>Entry Deadline:</strong>{' '}
                {new Date(raffle.entryDeadline).toLocaleString()}
              </Typography>
              {canViewEntries && raffleStatus && (
                raffleStatus === 'open' ? (
                  <Chip
                    label="Open to Enter"
                    color="success"
                    size="small"
                    sx={{ marginLeft: '0.5rem' }}
                    aria-label="Raffle is open to enter"
                  />
                ) : (
                  <Chip
                    label="Closed"
                    color="error"
                    size="small"
                    sx={{ marginLeft: '0.5rem' }}
                    aria-label="List is closed"
                  />
                )
              )}
            </Box>
            <Typography variant="body2">
              <strong>Max Entries per Address:</strong> {raffle.maxEntriesPerAddress}
            </Typography>
            <Typography variant="body2">
              <strong>Total Number of Entrants:</strong>{' '}
              {totalEntries !== null ? totalEntries : 'Loading...'}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          {(isOwner || hasSharedPermission) ? (
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: { xs: 'center', sm: 'left' } }}
              >
                Shareable Link:
              </Typography>
              <TextField
                value={`${window.location.origin}/List/${raffle.id}`}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                sx={{ mb: 2 }}
                style={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyLink}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {copied ? 'Link Copied!' : 'Copy Link'}
              </Button>
            </Box>
          ) : (
            <>
              {!isOwner && (
                <>
                  {brandLoading && (
                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'center', mb: 2 }}
                    >
                      Loading brand details...
                    </Typography>
                  )}
                  {brandError && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {brandError}
                    </Alert>
                  )}
                  {!brandLoading && !brandError && brand?.logoUrl && (
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                      <img
                        src={brand.logoUrl}
                        alt={`${brand.name} Logo`}
                        style={{ maxHeight: '100px', borderRadius: '8px' }}
                      />
                    </Box>
                  )}
                </>
              )}
              <Box
                sx={{
                  backgroundColor:
                    !isOwner && !hasSharedPermission && brand?.backgroundColor
                      ? brand.backgroundColor
                      : 'transparent',
                  color:
                    !isOwner && !hasSharedPermission && brand?.textColor
                      ? brand.textColor
                      : 'inherit',
                  p: 2,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <RaffleEntryForm
                    raffleId={raffle.id}
                    raffleType={raffle.raffleType}
                    user={user}
                    brand={brand}
                  />
                </Box>
              </Box>
            </>
          )}
        </Grid>

        {canViewEntries && (
          <Grid item xs={12}>
            <RaffleEntriesList raffleId={raffle.id} isRaffleOwner={isOwner || hasSharedPermission} />
          </Grid>
        )}
      </Grid>

      <Dialog open={openVerifiedDialog} onClose={() => setOpenVerifiedDialog(false)}>
        <DialogTitle>Account Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {raffle.ownerUsername ? (
              subscriptionCreated ? (
                `User ${raffle.ownerUsername} has been verified since ${formatSubDate()}.`
              ) : (
                `User ${raffle.ownerUsername} is verified, but no creation date was found.`
              )
            ) : (
              subscriptionCreated
                ? `This user has been verified since ${formatSubDate()}.`
                : 'This user is verified, but no creation date was found.'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerifiedDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RafflePage;

