// src/components/HandleUpgradeToPremiumButton.js

/**
 * @file        /src/components/HandleUpgradeToPremiumButton.js
 * @version     1.3.0
 * @author      Trevor Bissonette
 * @date        2025-01-20
 * @lastModified 2025-01-25
 * 
 * @summary
 * Provides a button component that handles the upgrade process to a premium subscription. Integrates with Stripe for payment processing
 * and manages the creation and monitoring of checkout sessions using Firebase Firestore.
 * 
 * @description
 * The `HandleUpgradeToPremiumButton` component is responsible for facilitating the upgrade of a user to a premium subscription within the
 * WalletCollector application. It leverages Firebase Firestore to create and listen to checkout sessions and utilizes Stripe for payment
 * processing. The component ensures a seamless user experience by providing real-time feedback during the checkout process and handling
 * various states such as successful redirection and error scenarios.
 * 
 * Key Features:
 * - **Stripe Integration:** Initiates Stripe Checkout sessions for premium upgrades, handling redirection to the Stripe payment page.
 * - **Firebase Firestore Usage:** Creates and listens to checkout session documents within Firestore to track the status of payment sessions.
 * - **User Authentication Handling:** Ensures that only authenticated users can initiate the upgrade process, prompting sign-in if necessary.
 * - **UI Enhancements:** Utilizes Material-UI (MUI) for consistent styling and provides interactive button animations for improved user experience.
 * - **Error Handling:** Implements comprehensive error handling to manage issues during session creation and listening, providing user feedback as needed.
 * - **Scalability Optimizations:** Structured to allow easy extension for additional payment features and subscription tiers in the future.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **1.3.0**: Added window focus listener to reset loading state when navigating back from Stripe.
 * - **1.2.0**: Enhanced error handling and logging for better debugging.
 * - **1.1.0**: Improved UI animations for the upgrade button.
 * - **1.0.0**: Initial creation of the HandleUpgradeToPremiumButton component with Stripe and Firebase integration.
 */

 // src/components/HandleUpgradeToPremiumButton.js

import React, { useState, useEffect } from 'react';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase'; // Ensure this path is correct
import { Button, CircularProgress } from '@mui/material'; // Import CircularProgress
import { styled } from '@mui/system';

/**
 * StyledButton component using Material-UI's styled API for enhanced styling.
 */
const StyledButton = styled(Button)(({ theme }) => ({
  mt: 2,
  px: 4,
  py: 1,
  position: 'relative',
  overflow: 'hidden',
  '& .original-text': {
    transition: 'opacity 0.3s',
    opacity: 1,
  },
  '&:hover .original-text': {
    opacity: 0,
  },
  '& .hover-text': {
    transition: 'opacity 0.3s',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover .hover-text': {
    opacity: 1,
  },
}));

/**
 * @component
 * HandleUpgradeToPremiumButton
 * 
 * Renders a button that allows authenticated users to upgrade to a premium subscription. Handles the creation of Stripe checkout sessions
 * and manages redirection based on the session status.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.user - The current authenticated user object.
 * 
 * @returns {JSX.Element} The rendered Upgrade to Premium button.
 */
const HandleUpgradeToPremiumButton = ({ user }) => {
  /**
   * Loading state to manage the display of the loading spinner.
   * @type {[boolean, Function]}
   */
  const [isLoading, setIsLoading] = useState(false); // Loading state

  /**
   * Resets the loading state when the window gains focus.
   */
  useEffect(() => {
    const handleFocus = () => {
      // Reset loading state when the window gains focus
      setIsLoading(false);
    };

    window.addEventListener('focus', handleFocus);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  /**
   * Initiates the creation of a Stripe checkout session and handles the redirection to the Stripe payment page.
   * 
   * @async
   * @function createCheckoutSession
   * @returns {Promise<void>} A promise that resolves when the checkout session is successfully created or an error occurs.
   */
  const createCheckoutSession = async () => {
    console.log('User:', user);
    console.log('User UID:', user ? user.uid : 'No user');

    if (!user || !user.uid) {
      alert('Please sign in first.');
      return;
    }

    setIsLoading(true); // Start loading

    try {
      // 1) Create a new doc in users/{uid}/checkout_sessions
      const docRef = await addDoc(
        collection(firestore, `users/${user.uid}/checkout_sessions`),
        {
          // Use your actual price ID from Stripe
          price: process.env.REACT_APP_STRIPE_PRICE_ID,

          // Include client_reference_id to associate the session with the user
          client_reference_id: user.uid,

          success_url: window.location.origin + '/profile', // Redirect to profile on success
          cancel_url: window.location.origin + '/profile', // Redirect to profile on cancel
          allow_promotion_codes: true,
        }
      );

      console.log('Checkout Session Document Created:', docRef.id);

      // 2) Listen for that doc to be updated with a session URL
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          const { url, error } = docSnap.data() || {};
          if (url) {
            console.log('Redirecting to Stripe Checkout:', url);
            // 3) Redirect to the Stripe Checkout page
            window.location.assign(url);
          }
          if (error) {
            console.error('Error creating checkout session:', error);
            alert('Failed to create a checkout session. Please try again.');
            setIsLoading(false); // Stop loading on error
          }
        },
        (error) => {
          console.error('Error listening to checkout_session:', error);
          alert('An error occurred while listening for the checkout session.');
          setIsLoading(false); // Stop loading on error
        }
      );

      // Optionally, unsubscribe after a certain time or based on your app's logic
      // For example, unsubscribe after 30 seconds to prevent memory leaks
      setTimeout(() => {
        unsubscribe();
        setIsLoading(false); // Ensure loading state is reset
      }, 30000); // 30,000 milliseconds = 30 seconds
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to create a checkout session. See console for details.');
      setIsLoading(false); // Stop loading on error
    }
  };

  return (
    <StyledButton
      variant="outlined"
      color="secondary"
      onClick={createCheckoutSession}
      disabled={isLoading} // Disable button while loading
      aria-busy={isLoading} // Accessibility attribute
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <>
          <span className="original-text">Upgrade to Premium</span>
          <span className="hover-text">Upgrade Today!</span>
        </>
      )}
    </StyledButton>
  );
};

export default HandleUpgradeToPremiumButton;

